import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { type FC, useCallback, useState } from 'react';

import Export from '@assets/Export';
import ExportActive from '@assets/ExportActive';
import { IconButtonTooltip } from '@components';

interface IExportButton {
  disabled: boolean;
  handleExportData: () => Promise<void>;
}

export const ExportButton: FC<IExportButton> = ({
  disabled,
  handleExportData,
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const onHandleMouseEnter = useCallback(() => {
    if (!disabled) {
      setIsHovered(true);
    }
  }, [disabled]);

  const onHandleMouseLeave = useCallback(() => {
    if (!disabled) {
      setIsHovered(false);
    }
  }, [disabled]);

  return (
    <IconButtonTooltip disableInteractive title="Export">
      <IconButton
        disableRipple
        sx={{
          width: '2.5rem',
          height: '2.5rem',
          background: theme.palette.primary.contrastBackground,
          '&:hover': {
            cursor: disabled ? 'default' : 'pointer',
            boxShadow: theme.customShadows.iconButton,
          },
        }}
        color="inherit"
        component="span"
        disabled={disabled}
        edge="start"
        onClick={() => void handleExportData()}
        onMouseEnter={onHandleMouseEnter}
        onMouseLeave={onHandleMouseLeave}
      >
        {isHovered ? <ExportActive /> : <Export />}
      </IconButton>
    </IconButtonTooltip>
  );
};
